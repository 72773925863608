.footer {
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.footer:not(.color-background-1) {
  border-top: none;
}

.footer__content-top {
  padding-bottom: 5rem;
  padding-top: 5rem;
  display: block;
}

@media screen and (max-width: 749px) {
  .footer .grid {
    display: block;
    margin-left: 0;
  }

  .footer-block.grid__item {
    padding: 0;
    margin: 4rem 0;
    width: 100%;
  }

  .footer-block.grid__item:first-child {
    margin-top: 0;
  }

  .footer__content-top {
    padding-bottom: 3rem;
    padding-left: calc(4rem / var(--font-body-scale));
    padding-right: calc(4rem / var(--font-body-scale));
  }
}

@media screen and (min-width: 750px) {
  .footer__content-top .grid {
    margin-left: -3rem;
    row-gap: 6rem;
    margin-bottom: 0;
  }

  .footer__content-top .grid__item {
    padding-left: 3rem;
  }
}

.footer__content-bottom {
  border-top: solid 0.1rem rgba(var(--color-foreground), 0.08);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.footer__content-bottom:only-child {
  border-top: 0;
}

.footer__content-bottom-wrapper {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 749px) {
  .footer__content-bottom {
    flex-wrap: wrap;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    row-gap: 1.5rem;
  }

  .footer__content-bottom-wrapper {
    flex-wrap: wrap;
    row-gap: 1.5rem;
  }
}

.footer__localization:empty + .footer__column--info {
  align-items: center;
}

@media screen and (max-width: 749px) {
  .footer__localization:empty + .footer__column {
    padding-top: 1.5rem;
  }
}
.footer__column {
  width: 100%;
  align-items: flex-end;
}

.footer__column--info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (min-width: 750px) {
  .footer__column--info {
    padding-left: 0;
    padding-right: 0;
    align-items: flex-end;
  }
}

.footer-block:only-child:last-child {
  text-align: center;
  max-width: 76rem;
  margin: 0 auto;
}

@media screen and (min-width: 750px) {
  .footer-block {
    display: block;
    margin-top: 0;
  }
}

.footer-block:empty {
  display: none;
}

.footer-block--newsletter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-top: 2rem;
}

.footer-block--newsletter:only-child {
  margin-top: 0;
}

.footer-block--newsletter > * {
  flex: 1 1 100%;
}

@media screen and (max-width: 749px) {
  .footer-block.footer-block--menu:only-child {
    text-align: left;
  }
}

@media screen and (min-width: 750px) {
  .footer-block--newsletter {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.footer-block__heading {
  margin-bottom: 2rem;
  margin-top: 0;
  font-size: calc(var(--font-heading-scale) * 1.6rem);
}

@media screen and (min-width: 990px) {
  .footer-block__heading {
    font-size: calc(var(--font-heading-scale) * 1.8rem);
  }
}

.footer__list-social:empty,
.footer-block--newsletter:empty {
  display: none;
}

.footer__list-social.list-social:only-child {
  justify-content: center;
}

.footer-block__newsletter {
  text-align: center;
}

.newsletter-form__field-wrapper {
  max-width: 36rem;
}

@media screen and (min-width: 750px) {
  .footer-block__newsletter:not(:only-child) {
    text-align: left;
  }

  .footer-block__newsletter:not(:only-child) .footer__newsletter {
    justify-content: flex-start;
    margin: 0;
  }

  .footer-block__newsletter:not(:only-child) .newsletter-form__message--success {
    left: auto;
  }
}

.footer-block__newsletter + .footer__list-social {
  margin-top: 3rem;
}

@media screen and (max-width: 749px) {
  .footer__list-social.list-social {
    justify-content: center;
  }
}

@media screen and (min-width: 750px) {
  .footer-block__newsletter + .footer__list-social {
    margin-top: 0;
  }
}

@media screen and (min-width: 750px) {
  .footer__content-bottom {
    border-top-width: 0.1rem;
  }
}

.footer__localization {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
}

.footer__localization:empty {
  display: none;
}

.localization-form {
  display: flex;
  flex-direction: column;
  flex: auto 1 0;
  padding: 1rem;
  margin: 0 auto;
}

.localization-form:only-child {
  display: inline-flex;
  flex-wrap: wrap;
  flex: initial;
  padding: 1rem 0;
}

.localization-form:only-child .button,
.localization-form:only-child .localization-form__select {
  margin: 1rem 1rem 0.5rem;
  flex-grow: 1;
  width: auto;
}

.footer__localization h2 {
  margin: 1rem 1rem 0.5rem;
  color: rgba(var(--color-foreground), 0.75);
}

@media screen and (min-width: 750px) {
  .footer__localization {
    padding: 0.4rem 0;
    justify-content: flex-start;
  }

  .localization-form {
    padding: 1rem 2rem 1rem 0;
  }

  .localization-form:first-of-type {
    padding-left: 0;
  }

  .localization-form:only-child {
    justify-content: start;
    width: auto;
    margin: 0 0 0 -1rem;
  }

  .localization-form:only-child .button,
  .localization-form:only-child .localization-form__select {
    margin: 1rem;
  }
}

@media screen and (max-width: 989px) {
  noscript .localization-form:only-child,
  .footer__localization noscript {
    width: 100%;
  }
}

.localization-form .button {
  padding: 1rem;
}

.localization-form__currency {
  display: inline-block;
}

@media screen and (max-width: 749px) {
  .localization-form .button {
    word-break: break-all;
  }
}

.localization-form__select {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.55);
  position: relative;
  margin-bottom: 1.5rem;
  border: 0.1rem solid transparent;
  padding-left: 1rem;
  text-align: left;
}

noscript .localization-form__select {
  padding-left: 0rem;
}

@media screen and (min-width: 750px) {
  noscript .localization-form__select {
    min-width: 20rem;
  }
}

.localization-form__select .icon-caret {
  position: absolute;
  content: '';
  height: 0.6rem;
  right: 1.5rem;
  top: calc(50% - 0.2rem);
}

.localization-selector.link {
  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: rgb(var(--color-foreground));
  width: 100%;
  padding-right: 4rem;
  padding-bottom: 1.5rem;
}

noscript .localization-selector.link {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}

.disclosure .localization-form__select {
  padding-top: 1.5rem;
}

.localization-selector option {
  color: #000000;
}

.localization-selector + .disclosure__list {
  margin-left: 1rem;
  opacity: 1;
  animation: animateLocalization var(--duration-default) ease;
}


@media screen and (min-width: 750px) {
  .footer__payment {
    margin-top: 1.5rem;
  }
}

.footer__copyright {
  text-align: center;
  margin-top: 1.5rem;
}

@media screen and (min-width: 750px) {
  .footer__copyright {
    text-align: right;
  }
}

/* .footer__copyright a {
  font-size: 1.3rem;
  text-decoration: none;
  color: currentColor;
}

.footer__copyright a:hover {
  opacity: 0.75;
} */

@keyframes appear-down {
  0% {
    opacity: 0;
    margin-top: -1rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.footer-block__details-content {
  margin-bottom: 4rem;
}

@media screen and (min-width: 750px) {
  .footer-block__details-content {
    margin-bottom: 0;
  }

  .footer-block__details-content > p,
  .footer-block__details-content > li {
    padding: 0;
  }

  .footer-block:only-child li {
    display: inline;
  }

  .footer-block__details-content > li:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.footer-block__details-content .list-menu__item--link,
.copyright__content a {
  color: rgba(var(--color-foreground), 0.75);
}

.footer-block__details-content .list-menu__item--active {
  transition: text-decoration-thickness var(--duration-short) ease;
  color: rgb(var(--color-foreground));
}

@media screen and (min-width: 750px) {
  .footer-block__details-content .list-menu__item--link:hover,
  .copyright__content a:hover {
    color: rgb(var(--color-foreground));
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }

  .footer-block__details-content .list-menu__item--active:hover {
    text-decoration-thickness: 0.2rem;
  }
}

@media screen and (max-width: 989px) {
  .footer-block__details-content .list-menu__item--link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 750px) {
  .footer-block__details-content .list-menu__item--link {
    display: inline-block;
    font-size: 1.4rem;
  }

  .footer-block__details-content > :first-child .list-menu__item--link {
    padding-top: 0;
  }
}

@media screen and (max-width: 749px) {
  .footer-block-image {
    text-align: center;
  }
}

.footer-block-image > img {
  height: auto;
}

.footer-block__details-content .placeholder-svg {
  max-width: 20rem;
}

.copyright__content a {
  color: currentColor;
  text-decoration: none;
}

@keyframes animateLocalization {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-1rem);
  }
}

.footer .disclosure__link {
  padding: 0.95rem 3.5rem 0.95rem 2rem;
  color: rgba(var(--color-foreground), 0.75);
}

.footer .disclosure__link:hover {
  color: rgb(var(--color-foreground));
}

.footer .disclosure__link--active {
  text-decoration: underline;
}
